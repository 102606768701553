import { Component } from '@angular/core';
import { AbstractModal } from '../abstract-modal';
import { ModalService } from '../../../services/modal.service';

@Component({
  selector: 'app-permissions-modal',
  templateUrl: './permissions-modal.component.html',
  styleUrls: ['./permissions-modal.component.scss']
})
export class PermissionsModalComponent extends AbstractModal {

  constructor(
    modalService: ModalService
  ) {
    super(modalService);
  }
}
