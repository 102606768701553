<div class="custom-modal-wrapper">
  <div class="close-icon-wrapper">
    <i class="icon-cancel-in-circle" (click)="closeModal()"></i>
  </div>
  <div class="custom-modal">
    <h3 class="modal-title">
      {{ 'VIDEO_RECORDER.PERMISSIONS_MODAL.TITLE' | translate }}
    </h3>
    <div class="modal-question-wrapper">
      <div class="modal-notice">
        {{ 'VIDEO_RECORDER.PERMISSIONS_MODAL.EXPLANATION' | translate }}
      </div>
    </div>
  </div>
</div>
